import axios from 'axios';
import router from "@/router";

// 获取用户信息（用于获取token）
const getUserToken = () => {
    let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
    return user.token || ''; // 如果token为空，返回空字符串
};

// 创建一个新的 axios 对象
const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL || 'http://localhost:9090',  // 后端的接口地址，支持环境变量配置
    timeout: 30000  // 30秒请求超时
});

// 请求拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';  // 设置请求头格式

    const token = getUserToken();  // 获取用户的 token
    if (token) {
        config.headers['token'] = token;  // 如果 token 存在，设置到请求头
    }

    return config;
}, error => {
    console.error('Request Error:', error); // 输出请求错误
    return Promise.reject(error);  // 传递错误
});

// 响应拦截器
request.interceptors.response.use(
    response => {
        let res = response.data;

        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            try {
                res = JSON.parse(res);  // 尝试解析字符串
            } catch (e) {
                console.error('Response parsing error:', e);  // 输出解析错误
            }
        }

        // 处理token过期的情况
        if (res.code === '401') {
            // token过期或无效，跳转到登录页
            localStorage.removeItem("xm-user");  // 清除无效的用户信息
            router.push('/login');  // 跳转到登录页面
        }

        return res;  // 返回响应数据
    },
    error => {
        console.error('Response Error:', error);  // 输出响应错误
        // 可以添加更多错误处理逻辑，例如弹出提示框等
        return Promise.reject(error);  // 传递错误
    }
);

export default request;